export default {
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flautistica"])},
  "navigation": [
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O meni"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/hr#about-me"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Događaji"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/hr#events"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerija"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/hr/media-video"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duo Eolian"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/hr#duo-eolian"])}
    }
  ],
  "index_nav_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/hr"])},
  "reviews": [
    {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postura flautistice Lucije Stilinović odavala je dojam interpretkinje kod koje nikada ništa neće poći po krivu, što je i dokazala izvedbom. Promatrajući i slušajući njeno samopouzdano muziciranje, slušatelji imaju osjećaj sigurnosti zbog čega se mogu prepustiti čistom užitku muziciranja obilježenog intuitivnim i prirodnim fraziranjem te logičnim glazbenim disanjem."])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helena Skljarov (Glazba.hr)"])},
      "for_what": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osvrt Helene Skljarov na koncert na Dubrovačkim ljetnim igrama – kolovoz 2022."])}
    },
    {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stilistička superiornost, intonativna perfekcija, punina tona, osjećaj za stil u glazbi različitih razdoblja, logično povezivanje dijelova glazbenog protoka u cjelini najuočljiviji su aduti Lucije Stilinović."])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Večernji list, September 2021"])},
      "for_what": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obrazloženja žirija Ferdo Livadić natjecanja (Tomislav Fačini, Ivana Kocelj, Branko Mihanović, Zdravko Šljivac i Srećko Bradić)."])}
    },
    {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flautistica Lucija Stilinović, solistica u Koncertu za flautu i gudače u G-duru CPE Bacha ostavila je sjajan dojam. Uz odličnu pratnju gudača svoju je dionicu izvela sigurno, tehnički suvereno, vrlo proživljeno i muzikalno uz pun i lijep ton te gotovo bez i najmanje pogreške. Duljina daha mlade glazbenice omogućila je da se istakne njezin jedinstven osjećaj za fraziranje, uz pomoć kojega je s lakoćom naglasila zaigranu osebujnost Bachove rano klasicističke partiture."])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treći program Hrvatskog radija, 2023."])},
      "for_what": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osvrt na koncert s Acoustic Project Strings na Samoborskoj glazbenoj jeseni - “7 dana glazbe"])}
    }
  ],
  "biography": {
    "title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O meni"])},
    "general": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tko sam"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flautistica Lucija Stilinović (Zagreb,1995.) aktivna je solistica, komorna i orkestralna glazbenica. Nastupila je na najpoznatijim glazbenim festivalima za klasičnu glazbu u Hrvatskoj, kao što su: Osorske Glazbene Večeri (kao solistica s Zagrebačkim solistima), Festival sv. Marka, Samoborska Glazbena Jesen (gdje je praizvela skladbu Chain Reaktion Mirele Ivičević), Scena Amadeo (zajedno s ansamblom Antiphonus) i Dubrovačke ljetne igre (kao solistica sa Dubrovačkim simfonijskim orkestrom)."])}
    },
    "accomplishments": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postignuća"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobitnica je Diplome Darko Lukić na 56. tribini Darko Lukić (2023.) u organizaciji Hrvatskog društva glazbenih umjetnika (HDGU). Pobjednica je 9. Hrvatskog natjecanja mladih glazbenih umjetnika Papandopulo (2021.), kao i, 17. Međunarodnog natjecanja mladih glazbenih umjetnika Ferdo Livadić (2020.). U 2020. godini Lucija je dobitnica stipendije Njujorške filharmonije (The Elaine and Stephen Stamas New York Philharmonic Scholarship Fund), druge nagrade na natjecanju Serge & Olga Koussevitsky Young Artists Awards 2019 u New Yorku. Također, nastupila je kao solistica uz Zagrebačku filharmoniju (2018.) i dobitnica je prve apsolutne nagrade na British Flute Society natjecanju u Londonu (2016.), prve nagrade na međunarodnom natjecanju Woodwind and brass u Varaždinu (2015.), itd. Kao solistica više je puta nastupala uz Varaždinski komorni orkestar, između ostalog i na Varaždinskim baroknim večerima (2011.). Održala je mnoge recitale u Hrvatskoj i inozemstvu, recital u sklopu Croatia Flute Academy (2020.), recital u Scandinavia House u New Yorku (2019.), koncert s ansamblom Melisse na Utrecht Early Music Festival u Nizozemskoj (2016.), koncert na St. John Smith Square tijekom London Flute Festivala (2016.) te je kao predstavnica Hrvatske glazbene mladeži pohađala radionicu New Ways in Classical Music u Strasbourgu (2018.). \n U jesen 2021. pozvana je da kao solo flautistica nastupa zajedno s Brooklyn Chamber Orchestra u Musikvereinu u Beču. 2018. godine bila je na turneji po Francuskoj zajedno sa Mediteranskim orkestrom mladih pod umjetničkim vodstvom Kazushija Ona (Festival International d’Art Lyrique d’Aix-en-Provence)."])}
    },
    "education": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrazovanje"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uz završenu Muzičku akademiju u Zagrebu u klasi Renate Penezić, diplomirala je na Manhattan School of Music (New York, SAD) u klasi Roberta Langevina, solo flautista Njujorške filharmonije. U akademskoj godini 2016./17. bila je studentica Kraljevskog konzervatorija u Antwerpenu (Belgija) u klasi Alda Baertena gdje je završila dodiplomski studij i stekla bakalaureat. Tijekom studija na Muzičkoj akademiji u Zagrebu bila je na razmjeni studenata Erasmus na HKU Konzervatoriju u Utrechtu (Nizozemska) u klasi Alda Baertena i Wilberta Hazelzeta (traverso flauta). U dva je navrata (2014. i 2017.) bila stipendistica International Music of Academy in the Principality of Liechenstein gdje je pohađala razne seminare i usavršavanja kod Philippe Bernolda. U jesen 2021. Lucija završava jednogodišnje usavršavanje na Music and Arts University of the city of Vienna u klasi Karl-Heinz Schütza, solo flautista Bečke opere i filharmonije. Usavršavala se kod mnogim uglednih flautista i pedagoga kao što su:  Denis Bouriakov, Emmanuel Pahud, Sir James Galway, Karl-Heinz Schütz, Philippe Bernold, Rachel Brown, Peter-Lukas Graf, i Andrea Lieberknecht. Surađivala je, isto tako, i s mnogim poznatim dirigentima, kao npr. Leonard Slatkin, Kazushi Ono, Jane Glover, Robert Kapilow, George Manahan, Gabriel Bebeselea i Dawid Runtz. "])}
    },
    "activities": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivnosti"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucija redovito surađuje i nastupa s uglednim hrvatskim pijanistima kao što su Zrinka Ivančić Cikojević i Danijel Detoni. U veljači 2022., kao solistica, nastupila je s Koncertom za flautu i orkestar Jacquesa Iberta uz pratnju Simfonijskog orkestra HRT-a. Također, u sezoni 2022./23. ostvarila je suradnju je sa Riječkim simfonijskim orkestrom (kao gostujuća solo flautistica) te Hrvatskim narodnim kazalištem u Varaždinu u sklopu opernog projekta Missy Mazzoli: Životi i smrti Isabelle Eberhardt. U jesen 2021. pozvana je da kao solo flautistica nastupa zajedno s Brooklyn Chamber Orchestra u Musikvereinu u Beču. 2018. godine bila je na turneji po Francuskoj zajedno sa Mediteranskim orkestrom mladih pod umjetničkim vodstvom Kazushija Ona (Festival International d’Art Lyrique d’Aix-en-Provence). U sezoni 2022./23. Lucija svira Prvu (Tutti) flautu u Zagrebačkoj filharmoniji."])}
    }
  },
  "events": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadolazeći događaji"])},
    "list": [
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. lipnja 2023. u 21:30h"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koncert Dua Eolian u Bologni (Italija)"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. srpnja 2023. u 21h"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koncert dua Eolian na otoku Rabu"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30. rujna 2023."])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solistički nastup s  “Acoustic Project Strings” na festivalu Samoborska glazbena jesen - Carl Philipp Emanuel Bach: Koncert za flautu u G-duru, Wq. 169  "])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15. listopada 2023. u 20h"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["u Studiju Zvonimir Bajsić – solistički nastup uz pratnju Tamburaškog orkestra HRTa"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23. listopada 2023. u 17:30h"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koncert dua Eolian u Trstu u “Museo Revoltella”  (Auditorium)"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16. studenog 2023."])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koncert dua Eolian u Centru za kulturu Novi Zagreb  “Novi val klasike”"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23. studenog 2023. u 19:30h"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koncert dua Eolian na Zagrebačkom harfističkom festivalu u Crkvi Sv. Franje Ksaverskog u Zagrebu"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13. siječnja 2024. u 11h."])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaktivno-edukativni koncert dua Eolian u Galeriji Kovačić Macolić u Varaždin"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28. siječnja 2024"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solistički nastup s Varaždinskim komornim orkestrom u Velikoj koncertnoj dvorani HNK u Varaždinu - François Devienne: Koncert za flautu i orkestar br. 7"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18. travnja 2024."])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koncert dua Eolian u Salonu Očić u Zagrebu"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28. svibnja 2024."])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koncert dua Stilinović - Riberio Rodrigues na “30. Sarajevske muziċke večeri”"])}
      }
    ]
  },
  "duo_eolian": {
    "title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duo Eolian"])},
    "text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duo Eolian - flautistica Lucija Stilinović i harfistica Veronika Ćiković – osnovan je 2018. godine, kada su obje glazbenice bile studentice Muzičke akademije u Zagrebu."])},
    "text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od tada duo Eolian često nastupa na poznatim festivalima za klasičnu glazbu u Hrvatskoj, kao što su: Dubrovačke ljetne igre, Svibanjske glazbene večeri u Šibeniku, Dani Alke i Velike Gospe u Sinju, Festival sv. Marka i Festival komorne glazbe u Čakovcu, Dvorana Circolo u Rijeci te HNK u Varaždinu. Važno je istaknuti kako je duo Eolian imao prilike izvesti Mozartov koncert za flautu i orkestar na Dubrovačkim ljetnim igrama (kolovoz 2022.) uz Dubrovački simfonijski orkestar i Maestra Dawida Runtza. Koncert je popraćen ovacijama pubike te prepoznat od strane kritike: “Posebno je bilo zanimljivo promotriti sinergiju obiju solistica: bilo je očito da su uložile mnogo vremena i truda u izradi ideja interpretacije što je, naravno, bilo najočitije u njihovim promišljenim kadencama. Odabirom boje tona uspjele su timbar toliko različitih instrumenata približiti jedan drugome: harfa je po potrebi imala savršen legato koji je istovremeno izvodila flauta ili je pak flauta izvodila tečnu i „pikavu“ melodijsku liniju imitirajući harfu. Pri tome su, uz savršenu zajedničku preciznost, bez imalo razilaženja, uvijek zajedno ‘disale’ u brojnim muzikalnim fraziranjima. “ -  Helena Skljarov, Glazba.hr "])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspjesi"])},
    "text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U rujnu 2022. duo Eolian osvojio je Prvo mjesto i Prvu nagradu na Međunarodnom natjecanju “Suoni d’Arpa” u Saluzzu, Italiji. Uz tu nagradu, duo je nagrađen i nagradom grada Saluzza te je pozvan održati nekoliko koncerata u Saluzzu i Bologni. U lipnju 2023., u sklopu festivala  Associazone musicale “Conoscere la Musica” duo je održao koncert u dvorcu “ Casstello dei Manzoli” u Bologni. Važno je spomenuti i da su Lucija i Veronika položile audiciju 2018. godine za Mediteranski orkestar mladih pod umjetničkim vodstvom Maestra Kazushija Ona te bile na turneji po Francuskoj (Festival International d’Art Lyrique d’Aix-en-Provence)."])}
  },
  "contact": {
    "title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktirajte me"])},
    "subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratite aktivnosti na društvenim mrežama ili pošaljite upit za angažman."])},
    "form_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "form_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poruka"])},
    "form_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša poruka"])},
    "btn_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji"])},
    "btn_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slanje..."])},
    "btn_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslano"])}
  },
  "gallery": {
    "title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerija fotografija"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snimke koncerta"])}
  }
}