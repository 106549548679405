import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MediaView from '../views/MediaView.vue'

const routes = [
  {
    path: '/',
    name: 'home-en',
    component: HomeView,
    beforeEnter: async (to, from, next) => {
      localStorage.setItem("lang","en")
      next()
      
    }
  },
  {
    path: '/media-video',
    name: 'media-en',
    component:MediaView,
    beforeEnter: async (to, from, next) => {
      localStorage.setItem("lang","en")
      next()
      
    }
  },
  {
    path: '/hr',
    name: 'home',
    component: HomeView,
    beforeEnter: async (to, from, next) => {
      localStorage.setItem("lang","hr")
      next()
      
    }
  },
  {
    path: '/hr/media-video',
    name: 'media',
    component:MediaView,
    beforeEnter: async (to, from, next) => {
      localStorage.setItem("lang","hr")
      next()
      
    }
  }
  
]

var locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');


const router = createRouter({
 
  history: createWebHistory(process.env.BASE_URL),
  base : (locale.trim().length && locale != "/") ? '/' + locale : undefined,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: 'smooth'
          })
        }, 500)
      })
    } else {
      return { top: 0 };
    }
  }
})

export default router
