<template>
  <div class="home">

    <div class="antialiased bg-body text-body font-body scroll-smooth">
      <div class="">

        <section class="relative overflow-hidden bg-black">

          <div class="">
            <div class="container px-4 mx-auto ">
              <div class="flex flex-wrap xl:items-center -mx-4">
                <div class="w-full md:w-1/2 px-4 mb-16 md:mb-0">

                  <h1 class="hidden md:block mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight font-bold tracking-tight"
                    contenteditable="false" style="font-family: 'Allura', cursive;">Lucija Stilinović</h1>
                  <p class="mb-8 text-lg md:text-xl font-medium text-coolGray-400" contenteditable="false">{{ $t('header')
                  }}</p>
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-auto py-1 md:py-0 md:mr-4"></div>
                    <div class="w-full md:w-auto py-1 md:py-0"></div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 px-4">
                  <div class="relative mx-auto md:mr-0 max-w-max">


                    <img class="relative rounded-7xl" src="../assets/images/Photo-jpeg-1685698858407.webp" alt=""
                      contenteditable="false">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="pt-12 bg-black" v-if="reviews.length > 0 ">
          <div id="reviewCarousel" class="relative" data-te-carousel-init data-te-carousel-slide data-te-interval="15000">
            <!--Carousel indicators-->


            <!--Carousel items-->
            <div class="relative w-full overflow-hidden after:clear-both after:block after:content-[''] " >
              <!--First item-->
              <div
                class="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item data-te-carousel-active>
                <div class="container px-4 mx-auto" id="review-div">
                  <div class="relative max-w-5xl sm:px-6 pb-6 mt-6 mb-10 mx-auto text-center">
                    <div class="relative">
                      <div class="text-xl md:text-3xl text-white font-semibold tracking-tighter">{{
                        this.reviews[0].text }}
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <h3 class="mb-2 text-xl md:text-2xl text-white font-semibold">{{ this.reviews[0].author }}
                    </h3>
                    <span class="text-lg text-coolGray-600 font-medium">{{ this.reviews[0].for_what }}</span>
                  </div>

                </div>
              </div>
              <!--Second item-->
              <div
                class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item>
                <div class="container px-4 mx-auto" id="review-div">
                  <div class="relative max-w-5xl sm:px-6 pb-6 mt-6 mb-10 mx-auto text-center">
                    <div class="relative">
                      <div class="text-xl md:text-3xl text-white font-semibold tracking-tighter">{{
                        this.reviews[1].text }}
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <h3 class="mb-2 text-xl md:text-2xl text-white font-semibold">{{ this.reviews[1].author }}
                    </h3>
                    <span class="text-lg text-coolGray-600 font-medium">{{ this.reviews[1].for_what }}</span>
                  </div>

                </div>
              </div>
              <div
                class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item>
                <div class="container px-4 mx-auto" id="review-div">
                  <div class="relative max-w-5xl sm:px-6 pb-6 mt-6 mb-10 mx-auto text-center">
                    <div class="relative">
                      <div class="text-xl md:text-3xl text-white font-semibold tracking-tighter">{{
                        this.reviews[2].text }}
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <h3 class="mb-2 text-xl md:text-2xl text-white font-semibold">{{ this.reviews[2].author }}
                    </h3>
                    <span class="text-lg text-coolGray-600 font-medium">{{ this.reviews[2].for_what }}</span>
                  </div>

                </div>
              </div>
            </div>
            <div class=" z-[2] mx-[15%] mb-4 flex list-none justify-center p-0" data-te-carousel-indicators>
              <button type="button" data-te-target="#reviewCarousel" data-te-slide-to="0" data-te-carousel-active
                class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-te-target="#reviewCarousel" data-te-slide-to="1"
                class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 2"></button>
                <button type="button" data-te-target="#reviewCarousel" data-te-slide-to="2"
                class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 2"></button>
            </div>

            <!--Carousel controls - prev item-->
            <button
              class="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button" data-te-target="#reviewCarousel" data-te-slide="prev">

            </button>
            <!--Carousel controls - next item-->
            <button
              class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button" data-te-target="#reviewCarousel" data-te-slide="next">
              <span class="inline-block h-8 w-8"></span>
            </button>
          </div>
          <!-- <div class="container px-4 mx-auto" id="review-div">
            <div class="relative max-w-5xl sm:px-6 pb-6 mt-6 mb-10 mx-auto text-center">
              <div class="relative">
                <div class="text-xl md:text-3xl text-white font-semibold tracking-tighter">{{
                  this.reviews[selected_review].text }}
                </div>
              </div>
            </div>
            <div class="text-center">
              <h3 class="mb-2 text-xl md:text-2xl text-white font-semibold">{{ this.reviews[selected_review].author
              }}
              </h3>
              <span class="text-lg text-coolGray-600 font-medium">{{ this.reviews[selected_review].for_what
              }}</span>
            </div>

          </div>
          <div class="text-center">
            <button
              :class="['inline-block h-3 w-3 mr-3 rounded-full', selected_review != 0 ? 'bg-coolGray-700' : 'bg-white']"
              @click="fadeText(0)"></button>
            <button
              :class="['inline-block h-3 w-3 mr-3 rounded-full', selected_review != 1 ? 'bg-coolGray-700' : 'bg-white']"
              @click="fadeText(1)"></button>
          </div> -->

        </section>

        <section class="py-12 bg-black" v-if="about.length > 0">
          <div class="container px-4 mx-auto">
            <h2 class="mb-4 text-4xl md:text-5xl leading-tight font-bold tracking-tighter w-full text-right"
              id="about-me">
              {{ $t("biography.title_1") }}</h2>
            <div class="flex flex-wrap -mx-4">
              <div class="px-4 mb-20 md:mb-0 w-full md:w-1/3">
                <div class="max-w-md">


                  <div><img src="../assets/images/Slika-5.jpeg" alt="" class="rounded shadow-md w-full"></div>
                </div>
              </div>
              <div class="w-full px-6 md:w-2/3 md:mt-16 " id="accordionFlushExample">

                <div class=" w-full mb-8 text-left accordion-group w-full mb-2 md:mb-0">
                  <div class="accordion-header items-center cursor-pointer flex flex-wrap w-full justify-between"
                    id="flush-headingOne" data-te-collapse-init data-te-target="#flush-collapseOne" aria-expanded="false"
                    aria-controls="flush-collapseOne">
                    <div class="">
                      <h3 class=" font-bold text-3xl md:mt-4" contenteditable="false">
                        {{about[0].title  }}</h3>
                    </div>
                    <div class="h-full align-middle text-4xl accordion-more">
                      +

                    </div>
                  </div>
                  <div id="flush-collapseOne" class="!visible border-0" data-te-collapse-item data-te-collapse-show
                    aria-labelledby="flush-headingOne" data-te-parent="#accordionFlushExample">
                    <p class="accordion-content h-64 md:h-auto font-medium text-md md:text-lg w-full md:overflow-hidden text-justify" contenteditable="false">
                      {{ about[0].text  }}
                    </p>
                  </div>

                </div>
                <div class=" w-full mb-8 text-left accordion-group w-full mb-2 md:mb-0">
                  <div class="accordion-header items-center cursor-pointer flex flex-wrap w-full justify-between"
                    id="flush-headingTwo" data-te-collapse-init data-te-target="#flush-collapseTwo" aria-expanded="false"
                    aria-controls="flush-collapseTwo">
                    <div class="">
                      <h3 class=" font-bold text-3xl md:mt-4" contenteditable="false">
                        {{ about[1].title }}</h3>
                    </div>
                    <div class="h-full align-middle text-4xl accordion-more">
                      +

                    </div>
                  </div>
                  <div id="flush-collapseTwo" class="!visible hidden border-0" data-te-collapse-item
                    aria-labelledby="flush-headingTwo" data-te-parent="#accordionFlushExample">
                    <p class="accordion-content h-64 md:h-auto font-medium text-md md:text-lg w-full md:overflow-hidden text-justify" contenteditable="false">
                      {{ about[1].text }}
                    </p>
                  </div>

                </div>
                <div class=" w-full mb-8 text-left accordion-group w-full mb-2 md:mb-0">
                  <div class="accordion-header items-center cursor-pointer flex flex-wrap w-full justify-between"
                    id="flush-headingTwo" data-te-collapse-init data-te-target="#flush-collapseThree"
                    aria-expanded="false" aria-controls="flush-collapseThree">
                    <div class="">
                      <h3 class=" font-bold text-3xl md:mt-4" contenteditable="false">
                        {{ about[2].title  }}</h3>
                    </div>
                    <div class="h-full align-middle text-4xl accordion-more">
                      +

                    </div>
                  </div>
                  <div id="flush-collapseThree" class="!visible hidden border-0" data-te-collapse-item
                    aria-labelledby="flush-headingThree" data-te-parent="#accordionFlushExample">
                    <p class="accordion-content h-64 md:h-auto font-medium text-md md:text-lg w-full md:overflow-hidden text-justify" contenteditable="false">
                      {{ about[2].text  }}
                    </p>
                  </div>

                </div>
                <div class=" w-full mb-8 text-left accordion-group w-full mb-2 md:mb-0">
                  <div class="accordion-header items-center cursor-pointer flex flex-wrap w-full justify-between"
                    id="flush-headingFour" data-te-collapse-init data-te-target="#flush-collapseFour"
                    aria-expanded="false" aria-controls="flush-collapseFour">
                    <div class="">
                      <h3 class=" font-bold text-3xl md:mt-4" contenteditable="false">
                        {{ about[3].title  }}</h3>
                    </div>
                    <div class="h-full align-middle text-4xl accordion-more">
                      +

                    </div>
                  </div>
                  <div id="flush-collapseFour" class="!visible hidden border-0" data-te-collapse-item
                    aria-labelledby="flush-headingFour" data-te-parent="#accordionFlushExample">
                    <p class="accordion-content h-64 md:h-auto font-medium text-md md:text-lg w-full md:overflow-hidden text-justify" contenteditable="false">
                      {{ about[3].text  }}
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section>

          <div>
            <section class="bg-white dark:bg-gray-900 antialiased">
              <div class="max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24 bg-black">
                <div class="max-w-3xl mx-auto text-center">
                  <h2 class="text-4xl font-extrabold leading-tight tracking-tight text-gray-200 dark:text-white"
                    contenteditable="false" id="events">
                    {{ $t("events.title") }}</h2>

                  <div class="mt-4">

                  </div>
                </div>

                <div class="flow-root max-w-3xl mx-auto mt-8 sm:mt-12 lg:mt-16">
                  <div class="-my-4 divide-y divide-gray-200 dark:divide-gray-700">
                    <div v-for="(single_ev, key) in event_list" :key="key"
                      class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center active">
                      <p
                        class="text-lg font-normal text-gray-300 sm:text-left shrink-0  w-full md:w-1/3">
                        {{ single_ev.date}}</p>
                      <h3 class="text-lg font-semibold text-gray-800 sm:text-left w-full" :class="single_ev.fut_ev ? 'dark:text-white' : ''">
                        <a href="#" class="hover:underline  ">{{ single_ev.title }}</a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>


        <section class="py-12 bg-black">
          <div class="container px-4 mx-auto" id="duo-eolian">
            <h3 class=" visible xl:hidden   mb-4 text-3xl md:text-4xl text-white font-bold tracking-tighter"
              contenteditable="false">Duo Eolian</h3>
            <div class="flex flex-wrap flex-col-reverse md:flex-row -mx-4 xl:items-center">
              <div class="w-full lg:w-1/2 px-4 lg:mb-0">
                <h3 class="hidden xl:block  mb-4 text-3xl md:text-4xl text-white font-bold tracking-tighter"
                  contenteditable="false">Duo Eolian</h3>
                <p class="text-md md:text-xl leading-8 font-semibold mb-3 text-justify" contenteditable="false">
                  {{ this.de_t1 }}</p>
                <p class="mb-6 text-md md:text-xl font-medium leading-7 text-coolGray-200 text-justify" contenteditable="false">
                  {{ this.de_t2 }}</p>
                <p></p>
                <h1 class="text-2xl mt-2 mb-6 leading-tight font-heading text-coolGray-50">{{ $t("duo_eolian.title_2") }}</h1>
                <p class="mb-6 text-md md:text-xl font-medium leading-7 text-coolGray-200 text-justify" contenteditable="false">
                  {{ this.de_t3 }}</p>

                <ol class="mb-5 list-decimal list-inside text-lg font-medium leading-7 text-coolGray-400"></ol>
              </div>
              <div class="mb-2 w-full lg:w-1/2 px-4 xl:mt-16">
                <img class="w-full rounded" src="../assets/images/DuoEolian.jpeg" alt="">
              </div>
            </div>
          </div>
        </section>
        <section class="py-5 md:py-20 ">
          <div class="container mx-auto">
            <h3 class="mb-4 text-4xl md:text-5xl font-bold tracking-tighter leading-tight text-center">
              {{ $t("contact.title_1") }}</h3>
            <p class="mb-4 text-xl font-bold tracking-tighter leading-tight text-center text-coolGray-500">
              {{ $t("contact.subtitle_1") }}</p>

            <div class="flex flex-wrap justify-center">
              <div class="w-full lg:w-auto px-8 lg:mb-0">

                <div class="flex justify-center -mx-4 ">
                  <div class="px-2 lg:px-4 mb-4 lg:mt-16">
                    <a href="https://instagram.com/lucijastilinovic?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" type="button"
                      data-te-ripple-init="" data-te-ripple-color="light"
                      class="mb-2  inline-block rounded-full p-3 text-l font-large uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                      style="background-color: #c13584">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 md:h-8 md:w-8" fill="currentColor"
                        viewBox="0 0 24 24">
                        <path
                          d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z">
                        </path>
                      </svg></a>
                    <a href="https://www.facebook.com/lucija.stilinovic" target="_blank" type="button"
                      data-te-ripple-init="" data-te-ripple-color="light"
                      class="mb-2 ml-5 md:ml-8 inline-block rounded-full p-3 text-l font-large uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                      style="background-color: #fff">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 md:h-8 md:w-8" fill="currentColor"
                        style="color: #1877f2" viewBox="0 0 24 24">
                        <path
                          d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                      </svg></a>
                    <a href="https://www.linkedin.com/mwlite/in/lucija-stilinovic-3861b316b" target="_blank" type="button"
                      data-te-ripple-init="" data-te-ripple-color="light"
                      class="mb-2 ml-5 md:ml-8 inline-block rounded-full p-4 text-l font-large uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                      style="background-color: #1877f2">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 md:h-8 md:w-8" fill="currentColor"
                        viewBox="0 0 24 24">
                        <path
                          d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                      </svg></a>
                    <a href="mailto:lucijastilinovic@gmail.com" type="button" data-te-ripple-init=""
                      data-te-ripple-color="light"
                      class="mb-2 ml-5 md:ml-8 inline-block rounded-full p-4 text-l font-large uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                      style="background-color: #DB4437">
                      <svg class=" text-white h-7 w-7 md:h-8 md:w-8" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.21 8.82L14 2.78C13.474 2.27986 12.7759 2.00095 12.05 2.00095C11.3241 2.00095 10.626 2.27986 10.1 2.78L3.89 8.78C3.61408 9.02087 3.39216 9.31731 3.23879 9.64991C3.08541 9.98251 3.00404 10.3438 3 10.71V19.29C3.01054 20.0176 3.30904 20.7114 3.83012 21.2193C4.35119 21.7273 5.05235 22.008 5.78 22H18.22C18.9476 22.008 19.6488 21.7273 20.1699 21.2193C20.691 20.7114 20.9895 20.0176 21 19.29V10.71C20.9992 10.3585 20.929 10.0106 20.7935 9.68623C20.6579 9.36189 20.4596 9.06752 20.21 8.82V8.82ZM11.44 4.22C11.593 4.08016 11.7927 4.00262 12 4.00262C12.2073 4.00262 12.407 4.08016 12.56 4.22L18 9.5L12.53 14.78C12.377 14.9198 12.1773 14.9974 11.97 14.9974C11.7627 14.9974 11.563 14.9198 11.41 14.78L6 9.5L11.44 4.22ZM19 19.29C18.9871 19.4863 18.8987 19.6699 18.7532 19.8023C18.6078 19.9347 18.4166 20.0056 18.22 20H5.78C5.58338 20.0056 5.39225 19.9347 5.24678 19.8023C5.10132 19.6699 5.01286 19.4863 5 19.29V11.35L9.05 15.25L7.39 16.85C7.20375 17.0374 7.09921 17.2908 7.09921 17.555C7.09921 17.8192 7.20375 18.0726 7.39 18.26C7.48295 18.3575 7.59463 18.4352 7.71836 18.4885C7.84208 18.5418 7.97529 18.5695 8.11 18.57C8.36747 18.569 8.61462 18.4687 8.8 18.29L10.57 16.59C11.0096 16.8586 11.5148 17.0008 12.03 17.0008C12.5452 17.0008 13.0504 16.8586 13.49 16.59L15.26 18.29C15.4454 18.4687 15.6925 18.569 15.95 18.57C16.0847 18.5695 16.2179 18.5418 16.3416 18.4885C16.4654 18.4352 16.5771 18.3575 16.67 18.26C16.8563 18.0726 16.9608 17.8192 16.9608 17.555C16.9608 17.2908 16.8563 17.0374 16.67 16.85L15 15.25L19 11.35V19.29Z"
                          fill="currentColor"></path>
                      </svg></a>



                  </div>

                </div>
              </div>
              <!-- <div class="w-full lg:w-1/2 px-4">
                <div class="px-4 py-8 md:p-10 rounded-md">
                  <form>
                    <div class="mb-6">
                      <label class="block mb-2 text-coolGray-300 font-medium leading-6" for="">{{ $t("contact.form_1")
                      }}</label>
                      <input
                        class="block w-full py-2 px-3 appearance-none border border-coolGray-200 rounded-lg shadow-md text-coolGray-500 leading-6 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                        type="email" placeholder="name@mail.com">
                    </div>
                    <div class="mb-6">
                      <label class="block mb-2 text-coolGray-300 font-medium leading-6" for="">{{ $t("contact.form_2")
                      }}</label>
                      <textarea
                        class="block h-32 md:h-52 w-full py-2 px-3 appearance-none border border-coolGray-200 rounded-lg shadow-md text-coolGray-500 leading-6 focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 placeholder-coolGray-200 resize-none"
                        type="text" :placeholder="$t('contact.form_3')"></textarea>
                    </div>
                    <button
                      class="block w-full py-4 px-6 text-lg leading-6 text-coolGray-50 font-medium text-center bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md shadow-sm">{{
                        $t("contact.btn_send") }}</button>
                  </form>
                </div>
              </div> -->
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import {
  Collapse,
  Carousel,
  initTE,
} from "tw-elements";



export default {
  name: 'HomeView',

  data() {
    return {
      reviews: [],
      about : [],
      de_t1 : "",
      de_t2 : "",
      de_t3 : "",
      pathname : window.location.pathname.replace(/^\/([^\/]+).*/i,'$1'),
      event_list: [],
      selected_review: 0
    }

  },
  methods: {
    fadeText(idx) {
      if (idx != this.selected_review) {
        let rev_id = document.getElementById("review-div")
        gsap.to(rev_id, {
          opacity: 0,
          duration: 0.5,
          ease: "power1.inOut"
        })
        gsap.to(rev_id, {
          opacity: 1,
          delay: 0.5,
          duration: 0.5,
          ease: "power1.inOut"
        })
        setTimeout(() => {
          this.selected_review = idx;
        }, 500)

      }

    }
  },

  async created() {
    const locale =  (this.pathname.trim().length && this.pathname != "/") ? this.pathname : "en-gb" || 'en-gb'
    const homepage = await this.$prismic.client.getSingle("homepage", {lang : locale});
    const events = await this.$prismic.client.getAllByType("events", {lang : locale, orderings : {field : "my.events.datum_kalendar", direction : "asc"} });

    const today = new Date();
  //  let idx_curr_event = 0;
  //   for(let i = 0; i<events.length; i++)
  //   {
  //     let tmp_ev = events[i]
  //     let tmp_date = new Date(tmp_ev.datum_kalendar)

  //   }

   this.event_list = (events.map((p)=>{

      return {
        date : this.$prismic.asText(p.data.datum),
        title : this.$prismic.asText(p.data.event),
        fut_ev : new Date(p.data.datum_kalendar) >= today
      }
    }))




    this.reviews = homepage.data.reviews.map((p)=>{
      return {
        text : p.text[0].text,
        author : p.title[0].text,
        for_what : p.subtitle[0].text
      }
    })
    this.about = homepage.data.about.map((p)=>{
      return {
        title : p.title[0].text,
        text : p.text[0].text,
      }
    })


    this.de_t1 =this.$prismic.asText( homepage.data.duo_eolian_txt_1)
    this.de_t2 =this.$prismic.asText( homepage.data.duo_eolian_txt_2)
    this.de_t3 =this.$prismic.asText( homepage.data.duo_eolian_succes)

    console.log(this.de_t1, this.de_t2, this.de_t3)
    setTimeout(()=>{
        initTE({ Collapse });
        initTE({ Carousel });
        }, 500)

    console.log("homepage reviews", this.reviews);
  },

  mounted() {
    // this.reviews = this.$tm('reviews')
  
    // console.log(this.$t('reviews'))
  }
}
</script>
