import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import { createHead } from '@vueuse/head'
import prismic from './services/prismic'

const head = createHead()



createApp(App).use(head).use(i18n).use(prismic).use(router).mount('#app')


