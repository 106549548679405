import { createPrismic } from "@prismicio/vue";
import router from "@/router";


const prismic = createPrismic({
    endpoint : "lucija-web",
    clientConfig : {
        defaultParams : {
            routes : router.getRoutes()
        }
    }
})

export default prismic;