export default {
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flutist"])},
  "navigation": [
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About me"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/#about-me"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/#events"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/media-video"])}
    },
    {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duo Eolian"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/#duo-eolian"])}
    }
  ],
  "index_nav_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/"])},
  "reviews": [
    {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The posture of flutist Lucija Stilinović gave the impression of an interpreter with whom nothing would ever go wrong, which she proved with her performance.Observing and listening to her self- confident musicianship, listeners have a sense of security, which is why they can indulge in the pure pleasure of musicianship marked by intuitive and natural phrasing and logical musical breathing."])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helena Skljarov (Glazba.hr)"])},
      "for_what": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review of the concert at the Dubrovnik Summer Festival - August 2022"])}
    },
    {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stylistic superiority, perfect intonation, the fullness of tone, sense of style in the music of different periods, logical connection of parts of the musical flow as a whole are the most noticeable advantages of Lucija Stilinović."])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Večernji list, September 2021"])},
      "for_what": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jury of the Ferdo Livadić competition (Tomislav Fačini, Ivana Kocelj, Branko Mihanović, Zdravko Šljivac, and Srećko Bradić)."])}
    },
    {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flutist Lucija Stilinović, soloist in the Concerto for Flute and Strings in G Major by CPE Bach, left a great impression. With an excellent string accompaniment, she performed her part with confidence, technically brilliant, very musical with a full and beautiful tone and almost without the slightest mistake. The length of the young musician’s breath made it possible to emphasize her unique sense of phrasing, which helped her easily emphasize the playfulness of Bach’s early classical score."])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian Radio HRT3, 2023."])},
      "for_what": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review of the concert with Acoustic Project Strings at the Samobor Music Festival - “ 7 Days of Music"])}
    }
  ],
  "biography": {
    "title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About me"])},
    "general": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who am I"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian flutist Lucija Stilinović is an active orchestral, chamber, and solo musician. She has performed on some of Croatia’s most well-known classical music festivals such as: Osor Musical Evenings with the Zagreb Soloists, St. Marko’s Festival, Scene Amadeo (ensemble Antiphonus), Samobor Festival, where she premiered a new piece written by Mirela Ivičević (2021) and Dubrovnik Summer Festival, where she performed as a soloist with the Dubrovnik Symphony Orchestra (2022). "])}
    },
    "accomplishments": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accomplishments"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her most recent achievements include First Prize at the Boris Papandopulo National Competition for Young Musicians and First Prize at the 17th International Competition for Young Musicians Ferdo Livadić, and the jury (Tomislav Fačini, Ivana Kocelj, Branko Mihanović, Zdravko Šljivac and Srećko Bradić) statement read as follows: ‘superior style, perfect pitch, full tone, sense of style in performing music of different periods and logical connection of the parts are the most outstanding features of Ms. Stilinović’s performance.’ \n In February 2022 she performed the Ibert Concerto with the Croatian Radio Symphony Orchestra.In Autumn 2021, Ms. Stilinović was invited by the Brooklyn Chamber Orchestra to play as a guest principal flutist at their concert in Musikverein, Vienna. In 2020 she became a Recipient of The Elaine and Stephen Stamas New York Philharmonic Scholarship Fund. In 2019 she won the Second Prize of the 2019 Serge & Olga Koussevitsky Young Artists Awards Competition. In June 2018 Ms. Stilinović performed the Nielsen Flute Concerto together with the Zagreb Philharmonic Orchestra. In the summer of 2018, she went on tour to France with the Mediterranean Youth Orchestra and Maestro Kazushi Ono - Festival International d’Art Lyrique d’Aix-en-Provence. She was also the winner of the 2016 British Flute Society Young Artist Competition, and was invited to perform in the BFS Flutastique Festival in London that same year."])}
    },
    "education": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ms. Stilinović holds degrees from the Academy of Music, University of Zagreb with Renata Penezić, Royal Conservatoire of Antwerp with Aldo Baerten, Manhattan School of Music with Robert Langevin, and the Certificate of Performance with Karl-Heinz Schütz at the Music and Arts University of the City of Vienna (MUK). She has attended Utrecht Conservatorium in The Netherlands as an exchange student, and was a Scholar of the International Music Academy in the Principality of Liechtenstein. \n Ms. Stilinović has performed in masterclasses with soloists such as Denis Bouriakov, Emmanuel Pahud, Sir James Galway, Karl-Heinz Schütz, Philippe Bernold, Rachel Brown, Peter-Lukas Graf, and Andrea Lieberknecht. She has performed under the baton of eminent conductors such as Leonard Slatkin, Kazushi Ono, Jane Glover, Robert Kapilow,George Manahan, Gabriel Bebeselea and Dawid Runtz."])}
    },
    "activities": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucija often plays recitals with Croatian renowned pianists such as Zrinka Ivančić Cikojević and Danijel Detoni. In addition to modern flute, Ms. Stilinović enjoys playing the piccolo and traverso flute, which she learnt from Rachel Brown and Wilbert Hazelzet and has performed with Ensemble Melisse at the Utrecht Early Music Festival in 2016. Ms. Stilinović has been appointed to the First Flute Tutti position with the Zagreb Philharmonic Orchestra for their 2022/23 season."])}
    }
  },
  "events": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming events"])},
    "list": [
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June 6, 2023 at 9:30pm"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concert Duo Eolian in Bologna (Italy)"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July 7, 2023 at 9pm"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concert Duo Eolian on the island Rab (Croatia)"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September 30, 2023"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soloist with the ensemble “Acoustic Project Strings” at the 48 th Samobor Festival"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 15, 2023 at 8pm"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio Zvonimir Bajsić – soloist with the Tamburaški orkestar HRTa"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 23, 2023 at 5:30pm"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concert Duo Eolian in Trieste, Italy at Museo Revoltella’s auditorium"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November 16, 2023"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concert Duo Eolian at “Centre for Culture Novi Zagreb"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November 23, 2023  at 7:30pm"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concert Duo Eolian at  Zagreb Harp Festival/ Glowing Harp  at Church “Sv. Franjo Ksaverski” in Zagreb"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January 13, 2023 at 11am"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concert Duo Eolian at the Kovačić Macolić Gallery in Varaždin"])}
      },
      {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January 28, 2024"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soloist with the Varaždin Chamber Orchestra François Devienne: Concerto for flute and orchestra no.7"])}
      }
    ]
  },
  "duo_eolian": {
    "title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duo Eolian"])},
    "text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duo Eolian - flutist Lucija Stilinović and harpist Veronika Ćiković - was founded in 2018, when both musicians were students of the Academy of Music in Zagreb."])},
    "text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since then, the duo Eolian often performs at well-known classical music festivals in Croatia, such as the Dubrovnik Summer Festival, Šibenik Musical Evenings, Days of the Alka, and the Assumption Sinj, St. Marko’s Festival in Zagreb and the Chamber Music Festival in Čakovec.It is important to point out that Eolian duo had the opportunity to perform Mozart's concerto for flute and orchestra at the Dubrovnik Summer Festival (August 2022) with the Dubrovnik Symphony Orchestra and Maestro Dawid Runtz. The concert was accompanied by ovations from the audience and was recognized by critics: \"It was especially interesting to observe the synergy of both soloists: it was obvious that they invested a lot of time and effort in creating interpretation ideas, which was, of course, most evident in their thoughtful cadences. By choosing the color of the tone, they managed to bring the timbre of so many different instruments closer to each other: if necessary, the harp had a perfect legato performed by the flute at the same time, or the flute performed a flowing and \"picky\" melodic line imitating the harp. At the same time, with perfect mutual precision, without the slightest divergence, they always \"breathed\" together in numerous musical phrasings. \" - Helena Skljarov, Glazba. hr"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accomplishments"])},
    "text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In September 2022, the Eolian duo won First Place and First Prize at the International Competition \"Suoni d'Arpa\" in Saluzzo, Italy. In addition to this award, the duo was awarded the prize of the city of Saluzzo and was invited to perform several concerts in Saluzzo and Bologna. It is also important to mention that Lucija and Veronika passed the audition in 2018 for the Mediterranean Youth Orchestra under the artistic direction of Maestro Kazushi Ono and were on tour in France (Festival International d'Art Lyrique d'Aix-en-Provence)."])}
  },
  "contact": {
    "title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact me"])},
    "subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow me on social media or contact me directly for booking arrangements."])},
    "form_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "form_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "form_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
    "btn_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "btn_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending..."])},
    "btn_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])}
  },
  "gallery": {
    "title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo gallery"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performances"])}
  }
}