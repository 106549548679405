<template>
  <div class="home">
    <div class="antialiased bg-body text-body font-body scroll-smooth">
      <div class="">
        <h1 class="text-3xl mt-2 mb-6 leading-tight font-heading text-center">
          {{ $t("gallery.title_1") }}
        </h1>

        <div class="md:p-4 bg-black px-6">
          <!-- Carousel -->

          <div
            id="carouselExampleControls"
            class="relative md:h-screen"
            data-te-carousel-init
            data-te-carousel-slide
          >
            <!--Carousel items-->
            <div
              class="relative w-full overflow-hidden after:clear-both after:block after:content-['']"
            >
              <!--First item-->
              <div
                class="relative float-left -mr-[100%] h-auto w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
                data-te-carousel-active
              >
                <img
                  :src="first_image"
                  class="block max-h-screen w-auto m-auto"
                  alt="Wild Landscape"
                />
              </div>
              <!--Second item-->
              <div
                v-for="(img_single, key) in slider_images"
                :key="key"
                class="relative float-left -mr-[100%] hidden w-full h-full ransition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
              >
                <img
                  :src="img_single"
                  class="block max-h-screen w-auto m-auto"
                  alt="Camera"
                />
              </div>

              <!--Carousel controls - prev item-->
              <button
                class="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                type="button"
                data-te-target="#carouselExampleControls"
                data-te-slide="prev"
              >
                <span class="inline-block h-8 w-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                </span>
                <span
                  class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                  >Previous</span
                >
              </button>
              <!--Carousel controls - next item-->
              <button
                class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                type="button"
                data-te-target="#carouselExampleControls"
                data-te-slide="next"
              >
                <span class="inline-block h-8 w-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </span>
                <span
                  class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                  >Next</span
                >
              </button>
            </div>
            <!-- Carousel -->
          </div>
        </div>
      </div>
    </div>
    <div class="container px-6 mt-6 m-auto bg-black">
      <h1 class="text-3xl mt-2 mb-6 leading-tight font-heading text-center">
        {{ $t("gallery.title_2") }}
      </h1>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 bg-black">
        <div v-for="(single_video,key) in video_embeds" :key="key">
          <div class="video-container">
            <iframe
              width="560"
              height="315"
              :src="single_video"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
import gsap from "gsap";
import { Carousel, initTE } from "tw-elements";

export default {
  name: "MediaView",
  data() {
    return {
      slider_images: [],
      first_image: "",
      video_embeds : [],
      links: [],
      reviews: [
        {
          text: '"The posture of flutist Lucija Stilinović gave the impression of an interpreter with whom nothing would ever go wrong, which she proved with her performance.Observing and listening to her self- confident musicianship, listeners have a sense of security, which is why they can indulge in the pure pleasure of musicianship marked by intuitive and natural phrasing and logical musical breathing."',
          author: "Helena Skljarov (Glazba.hr)",
          for_what:
            "review of the concert at the Dubrovnik Summer Festival - August 2022",
        },
        {
          text: '"Stylistic superiority, perfect intonation, the fullness of tone, sense of style in the music of different periods, logical connection of parts of the musical flow as a whole are the most noticeable advantages of Lucija Stilinović", says the explanation of the jury of the Ferdo Livadić competition (Tomislav Fačini, Ivana Kocelj, Branko Mihanović, Zdravko Šljivac, and Srećko Bradić)."',
          author: "Večernji list, September 2021",
          for_what: "",
        },
      ],
      selected_review: 0,
    };
  },
  methods: {
    fadeText(idx) {
      if (idx != this.selected_review) {
        let rev_id = document.getElementById("review-div");
        gsap.to(rev_id, {
          opacity: 0,
          duration: 0.5,
          ease: "power1.inOut",
        });
        gsap.to(rev_id, {
          opacity: 1,
          delay: 0.5,
          duration: 0.5,
          ease: "power1.inOut",
        });
        setTimeout(() => {
          this.selected_review = idx;
        }, 500);
      }
    },
  },
  async created() {
    const gallery = await this.$prismic.client.getSingle("gallery");

    console.log(gallery.data);

    this.video_embeds = gallery.data.concert_link_embeding.map((p)=> p.videos.url)
    console.log(this.video_embeds, "video")
    for (let i = 0; i < gallery.data.image_gallery.length; i++) {
      let image_url = gallery.data.image_gallery[i].image.url;

      if (i == 0) {
        this.first_image = image_url;
      } else {
        this.slider_images.push(image_url);
      }
    }

    initTE({ Carousel });
  },

  mounted() {
    // for (let i = 2; i <= 33; i++) {
    //   this.slider_images.push(require("../assets/images/carousel/crsl-" + i + ".jpg"))
    // }
  },
};
</script>
