<template>
    <section class="relative overflow-hidden bg-black">
        <div class="bg-tarnsparent">
            <nav class="flex justify-between p-6 px-4">
                <div class="flex xl:hidden justify-between w-full">
                    <div class="w-auto xl:w-1/3">
                        <router-link :to="$t('index_nav_link')" class="block max-w-max text-white" href="#">
                            <div class="text-4xl" style="font-family: 'Allura', cursive;">Lucija Stilinović</div>
                        </router-link>
                    </div>
                        <button class="navbar-burger self-center xl:hidden z-20 ">
                            <svg viewBox="0 0 12 10" class="hamburger w-14">
                                <path d="M10,2 L2,2" class="upper" style="fill: none;stroke: #fff;stroke-linecap: round;" />
                                <path d="M2,5 L10,5" class="middle"
                                    style="fill: none;stroke: #fff;stroke-linecap: round;" />
                                <path d="M10,8 L2,8" class="lower" style="fill: none;stroke: #fff;stroke-linecap: round;" />
                            </svg>

                        </button>
                        <div id="mobile-nav"
                            class="navbar-menu fixed top-0 left-0 z-10 w-full h-full bg-opacity-80 bg-black flex flex-col xl:hidden justify-center items-center  gap-16 transition duration-500 ease-in-out hidden">
                            <ul class="flex flex-col gap-4 -my-4">
                                <li class="main-link" v-for="(link, key) in this.$tm('navigation')" :key="link"><router-link
                                        :to="$rt(link.link)"
                                        class="hover:text-gray-200 text-gray-500 font-medium text-2xl py-4 my-4"
                                        contenteditable="false">{{ $rt(link.name) }}</router-link></li>


                            </ul>

                            <ul class="flex justify-center">
                                <li class="mr-4 main-link" @click="changeLanguage('en')"><router-link :to="$t('index_nav_link')"
                                        contenteditable="false"
                                        :class="[selected_lang == 'en' ? 'text-gray-200' : 'text-gray-500', 'hover:text-gray-200 active:text-gray-200 active  font-medium text-2xl']">en</router-link>
                                </li>
                                <li class="mr-4">
                                    <div
                                        class="inline-block h-10 w-0.5 self-stretch bg-gray-500 opacity-100 dark:opacity-50">
                                    </div>
                                </li>
                                <li class="mr-2 main-link" @click="changeLanguage('hr')"><router-link to="/hr"
                                        :class="[selected_lang == 'hr' ? 'text-gray-200' : 'text-gray-500', 'hover:text-gray-200 active:text-gray-200 active  font-medium text-2xl']"
                                        contenteditable="false">hr</router-link></li>

                            </ul>

                        </div>



                </div>
                <div class="hidden xl:flex justify-between items-center w-full ">
                    <div class="w-auto xl:w-1/3" >
                        <router-link :to="$t('index_nav_link')" v-if="isHomepage()" class="block max-w-max text-white " href="#">
                            <div class="text-3xl" style="font-family: 'Allura', cursive;">Lucija Stilinović</div>
                        </router-link>
                    </div>
                    <div class="w-1/2 xl:w-1/2">
                        <ul class=" flex xl:justify-center">
                            <li class="mr-12" v-for="(link, key) in this.$tm('navigation')" :key="link"><router-link
                                    :to="$rt(link.link)" class="hover:text-gray-200 text-gray-500 font-medium"
                                    contenteditable="false">{{ $rt(link.name) }}</router-link></li>
                        </ul>
                    </div>
                    <div class="w-1/2 xl:w-1/3">
                        <div class="hidden xl:flex items-center justify-end">
                            <ul class="hidden xl:flex xl:justify-center">
                                <li class="mr-4" @click="changeLanguage('en')"><router-link to="/" contenteditable="false"
                                        :class="[selected_lang == 'en' ? 'text-gray-200' : 'text-gray-500', 'hover:text-gray-200 active:text-gray-200 active  font-medium']">en</router-link>
                                </li>
                                <li class="mr-4">
                                    <div
                                        class="inline-block h-6 w-0.5 self-stretch bg-gray-500 opacity-100 dark:opacity-50">
                                    </div>
                                </li>
                                <li class="mr-2" @click="changeLanguage('hr')"><router-link to="/hr"
                                        :class="[selected_lang == 'hr' ? 'text-gray-200' : 'text-gray-500', 'hover:text-gray-200 active:text-gray-200 active  font-medium']"
                                        contenteditable="false">hr</router-link></li>

                            </ul>
                        </div>
                    </div>
                </div>

            </nav>
        </div>


    </section>
</template>
<script>
import gsap from "gsap"
export default {
    name: 'HomeView',
    data() {
        const lang = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1')

        return {
            selected_lang:  (lang.trim().length && lang != "/") ? lang : "en"  ,
            links: this.$tm("navigation")
        }

    },
    methods: {
        changeLanguage(value) {
            localStorage.setItem("lang", value);
            window.location.reload();
        },
        isHomepage() {
            return this.$route.name != undefined ? !this.$route.name.startsWith("home") : false;
        }
    },
    mounted() {
        var upper = document.getElementsByClassName('upper');
        var middle = document.getElementsByClassName('middle');
        var lower = document.getElementsByClassName('lower');
        var mainMenu = document.getElementById('mobile-nav');

        var tl = new gsap.timeline({ paused: true, reversed: true });

        tl
            .to(upper, 0.5, { attr: { d: "M8,2 L2,8" }, x: 1, ease: "power1.inOut" }, 'start')
            .to(middle, 0.5, { autoAlpha: 0 }, 'start')
            .to(lower, 0.5, { attr: { d: "M8,8 L2,2" }, x: 1, ease: "power1.inOut" }, 'start');


        document.querySelector('.hamburger').addEventListener('click', function () {
            tl.reversed() ? tl.play() : tl.reverse();
            mainMenu.classList.toggle('hidden');
        })
        document.querySelectorAll(".main-link").forEach((p) => {
            p.addEventListener('click', function () {
                tl.reversed() ? tl.play() : tl.reverse();
                mainMenu.classList.toggle('hidden');
            })

        })

    }

}
</script>
